var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", {
        directives: [
          {
            name: "html-safe",
            rawName: "v-html-safe",
            value: _vm.editor_data,
            expression: "editor_data",
          },
          {
            name: "tooltip",
            rawName: "v-tooltip.auto",
            value: {
              content: "Editar conteúdo",
              delay: { show: 1000, hide: 100 },
            },
            expression:
              "{\n      content: 'Editar conteúdo',\n      delay: { show: 1000, hide: 100 },\n    }",
            modifiers: { auto: true },
          },
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.readOnly &&
              !_vm.isEmpty(_vm.editor_data) &&
              !_vm.itemFocusSelected,
            expression:
              "readOnly && !isEmpty(editor_data) && !itemFocusSelected",
          },
        ],
        staticClass: "editor_data editor-content ck-content",
        attrs: { id: "editorData" },
        on: { click: _vm.turnEditorVisible },
      }),
      _vm.readOnly && _vm.isEmpty(_vm.editor_data) && !_vm.itemFocusSelected
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.auto",
                  value: {
                    content: "Editar conteúdo",
                    delay: { show: 1000, hide: 100 },
                  },
                  expression:
                    "{\n      content: 'Editar conteúdo',\n      delay: { show: 1000, hide: 100 },\n    }",
                  modifiers: { auto: true },
                },
              ],
              staticClass: "editor_data",
              on: { click: _vm.turnEditorVisible },
            },
            [_c("span", [_vm._v(_vm._s(_vm.placeholder))])]
          )
        : _vm._e(),
      (_vm.editor && !_vm.readOnly) || _vm.itemFocusSelected
        ? _c("ckeditor", {
            key: _vm.editorKey,
            ref: "editor",
            attrs: { editor: _vm.editor, config: _vm.editorConfig },
            on: {
              input: _vm.dataChanged,
              blur: _vm.onBlur,
              destroy: _vm.onDestroy,
              ready: _vm.onReady,
            },
            model: {
              value: _vm.editor_data,
              callback: function ($$v) {
                _vm.editor_data = $$v
              },
              expression: "editor_data",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }